import { onDropdownKeydown } from "../utils"

function updateExpanded(el) {
  el.querySelector("button").setAttribute(
    "aria-expanded",
    el.classList.contains("expanded").toString()
  )
}

export default function Dropdown(el) {
  const docClickListener = (e) => {
    if (el.classList.contains("expanded") && !el.contains(e.target)) {
      el.classList.remove("expanded")
      updateExpanded(el)
      document.removeEventListener("click", docClickListener)
    }
  }
  el.addEventListener("keydown", (e) => onDropdownKeydown(e, el))

  el.querySelector("button").addEventListener("click", (e) => {
    e.stopPropagation()
    el.classList.toggle("expanded")
    updateExpanded(el)

    if (el.classList.contains("expanded")) {
      document.addEventListener("click", docClickListener)
    }
  })
}

import "regenerator-runtime/runtime.js"

import { render } from "lit-html"

import Messages from "./js/generic/messages"
import updateNotifications from "./js/generic/notifications"
import FormInputs from "./js/forms"
import Dropdown from "./js/generic/dropdown"
import ScrollTopButton from "./js/generic/scroll-top"
import Toggle from "./js/generic/toggle"
import { renderAssignmentAppForm } from "./js/forms/assignment-app"
import { renderEventRegButtonForm } from "./js/forms/event-reg"
import DocumentModalForm from "./js/forms/document-modal"
import LabeledDate from "./js/inputs/labeled-date"
import ImageModal from "./js/embeds/image"
import { renderTwitterEmbed } from "./js/embeds/twitter"
import { renderImageGallery } from "./js/embeds/image-gallery"
import { keyClickWrapper } from "./js/utils"
import { setupFontawesomeIcons } from "./js/icons"
import { setupSiteSearch } from "./js/inputs/site-search"
import PaymentSection from "./js/payment"

import "./css/style.css"

function onLoad() {
  window.htmx = require("htmx.org")

  const assetPathMeta = document.head.querySelector(
    "meta[name='asset-public-path']"
  )
  if (assetPathMeta) {
    // eslint-disable-next-line
    __webpack_public_path__ = `${assetPathMeta.content}bundles/`
  }

  setupFontawesomeIcons()

  // Setup notification update events
  Messages()

  document.querySelectorAll(`[data-action="dropdown"]`).forEach(Dropdown)
  document.querySelectorAll(`[data-action="toggle"]`).forEach(Toggle)
  document.querySelectorAll("form").forEach(FormInputs)

  document
    .querySelectorAll("[data-action='site-search']")
    .forEach(setupSiteSearch)

  document
    .querySelectorAll(`[data-action="scroll-to-top"]`)
    .forEach((el) => render(ScrollTopButton(), el))

  document
    .querySelectorAll("[data-form='assignment-app']")
    .forEach(renderAssignmentAppForm)

  document
    .querySelectorAll("[data-form='event-reg']")
    .forEach(renderEventRegButtonForm)

  document.querySelectorAll("[data-image-src]").forEach((el) => {
    el.addEventListener("click", () => ImageModal(el))
    el.addEventListener("keydown", (e) =>
      keyClickWrapper(e, () => ImageModal(el))
    )
  })

  document.querySelectorAll("input[type='date']").forEach(LabeledDate)

  document
    .querySelectorAll("[data-embed='twitter']")
    .forEach(renderTwitterEmbed)

  document
    .querySelectorAll(`[data-component="image-gallery"]`)
    .forEach(renderImageGallery)

  document
    .querySelectorAll("[data-action='document-modal']")
    .forEach(DocumentModalForm)

  document.querySelectorAll(`[data-component="payment"]`).forEach((el) => {
    render(PaymentSection(el.dataset.endpoint, el.dataset.blockMinutes), el)
  })

  if (
    document.head.querySelector("meta[name='user-signed-in'][content='true']")
  ) {
    window.updateNotifications = updateNotifications
    updateNotifications()
  }

  // Setup color shift for area menu on scroll
  if (window.IntersectionObserver) {
    const areaNav = document.getElementById("nav-top")
    const mainNav = document.getElementById("nav-title")
    if (areaNav === null || mainNav === null) {
      return
    }

    const handleNavScrollIntersect = (entries) => {
      entries.forEach((entry) => {
        areaNav.classList.toggle("active", entry.intersectionRatio < 1)
      })
    }
    new IntersectionObserver(handleNavScrollIntersect, {
      root: null,
      rootMargin: "0px",
      threshold: [1, 0.999],
    }).observe(mainNav)
  }

  // Lazy load clipboard if targets available
  if (document.querySelectorAll("[data-clipboard-target]").length) {
    import(/* webpackChunkName: "clipboard" */ "clipboard").then((module) => {
      const Clipboard = module.default
      // eslint-disable-next-line
      new Clipboard("[data-clipboard-target]")
    })
  }
}

document.addEventListener("DOMContentLoaded", onLoad)

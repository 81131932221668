import { html, render } from "lit-html"
import { repeat } from "lit-html/directives/repeat"
import { virtual, useState } from "haunted"
import Modal from "../generic/modal"

export const ImageGallery = virtual(({ images, removeModal }) => {
  const [activeIdx, setActiveIdx] = useState(0)
  const { src: activeSrc, name: activeName, creator } = images[activeIdx]

  return html`${Modal({
    className: `image-gallery-modal`,
    onClose: removeModal,
    children: html`
        <div class="modal-content w-full h-screen flex flex-col items-center justify-end flex-grow">
          <div class="image">
            <img src=${activeSrc} alt=${activeName} />
            ${
              creator
                ? html` <small class="text-white text-sm">${creator}</small> `
                : ``
            }
          </div>
          <div class="min-h-144 max-w-full flex flex-row flex-nowrap scroll-x-no-scrollbar px-6">
            ${repeat(
              images,
              ({ id }) => id,
              ({ name, thumbnail }, idx) => html`
                <div class="thumbnail-container">
                  <button
                    type="button"
                    class="link-text-color border-2 ${activeIdx === idx
                      ? `border-blue`
                      : `border-transparent`} focus:border-red"
                    @click=${() => setActiveIdx(idx)}
                  >
                    <span class="thumbnail mb-0">
                      <img alt=${name} src=${thumbnail} />
                    </span>
                  </button>
                </div>
              `
            )}
          </div>
        </div>
        <button
          @click=${() => setActiveIdx(Math.max(0, activeIdx - 1))}
          class="absolute pr-2 left-16 w-10 text-xl text-white text-red-active"
          aria-label="previous"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
        <button
          @click=${() =>
            setActiveIdx(Math.min(images.length - 1, activeIdx + 1))}
          class="absolute pl-2 right-16 w-10 text-xl text-white text-red-active"
          aria-label="next"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
        <button
          @click=${removeModal}
          class="modal-close delete text-red-active"
          aria-label="close"
        ></button>
      </div>
    `,
  })}`
})

export function renderImageGallery(el) {
  const modalMount = document.getElementById("modal-mount")

  const removeModal = () => {
    el.querySelector("button").focus()
    render(html``, modalMount)
  }

  const images = [
    ...el.querySelectorAll(`[data-component="gallery-image"]`),
  ].map((imageEl) => ({
    name: imageEl.dataset.name,
    id: imageEl.dataset.id,
    src: imageEl.dataset.src,
    thumbnail: imageEl.dataset.thumbnail,
    creator: imageEl.dataset.creator || null,
  }))

  el.querySelector("button").addEventListener("click", () => {
    render(
      ImageGallery({
        images,
        removeModal,
      }),
      modalMount
    )
  })
}
